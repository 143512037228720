// geolocation.js

document.addEventListener("DOMContentLoaded", function() {
  getLocation();
});

function getLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition);
  } else {
    alert("Geolocalização não é suportada pelo seu navegador.");
  }
}

function showPosition(position) {
  var latitude = position.coords.latitude;
  var longitude = position.coords.longitude;

  $.ajax({
    type: 'GET',
    url: '/votacao_location',
    cache: true,
    data: {
      latitude: latitude,
      longitude: longitude
    },
    success: function(response) {
      console.log('Valor de accuracy enviado com sucesso para o servidor Rails.');
    },
    error: function(xhr, status, error) {
      console.error('Erro ao enviar o valor de accuracy para o servidor Rails:', error);
    }
  });
}
